/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "We listopadzie 2023 r. Transpol Lider Sp. z o.o. Sp. k. zakończył zadanie inwestycyjne polegające na rozbudowie wału przeciwpowodziowego rzeki Wisły w Dolinie Stężyckiej."), "\n", React.createElement(_components.p, null, "W ramach przedmiotu zamówienia zostało wykonane oczyszczenie dna starorzecza z wykorzystaniem gruntu do rozbudowy obwałowania. Odbudowywane dno starorzecza będzie miało zmienną szerokość od 5 do 25 m i zmienne nachylenie skarp od 1:1 do 1:6"), "\n", React.createElement(_components.p, null, "Projektowana kubatura wydobytego urobku wynosi ok. 85 tys. m3 gruntów piaszczystych oraz ok 24 tys. m3 namułów."), "\n", React.createElement(_components.p, null, "Czas realizacji inwestycji przewidziany był na 3 miesiące od daty rozpoczęcia robót."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
